.list {
  display: flex;
  flex-direction: column;
  gap: 28px;
  list-style: none;
  padding: 30px 0 10px;
  margin: 0;
  align-self: stretch;
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-list-item {
  display: flex;
  justify-content: space-between;
}

.action {
  display: flex;
  gap: 10px;
}

.product-list-item {
  display: flex;
  justify-content: space-between;
}

.chip-wrapper {
  display: flex;
  gap: 10px;
}
