.spinner {
  border-radius: 4px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  cursor: default;
}

.spinner::before {
  content: '';
  width: 19px;
  height: 19px;
  border: 3px solid lightgray;
  border-bottom-color: green;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
