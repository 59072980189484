.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 20px;
  align-self: stretch;
}

.title {
  font-family: 'Nunito';
  font-size: 64px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  align-self: stretch;
}

.link {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  background-color: #5ec343;
  border-radius: 4px;
  transition: opacity 200ms linear;
}

.link:hover {
  opacity: 0.7;
  transition: opacity 200ms linear;
}

.link-wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: #e8ebf0;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
}

@media screen and (min-width: 768px) {
  .link-wrapper {
    flex-direction: row;
  }

  .wrapper {
    align-self: center;
  }
}
