.link {
  text-decoration: none;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper {
  position: relative;
}

.count {
  min-width: 18px;
  height: 18px;
  position: absolute;
  top: -9px;
  right: -8px;
  font-size: 10px;
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.text {
  font-size: 12px;
  color: #222222;
}
