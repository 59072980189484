.add-to-cart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fc0;
  height: 42px;
  width: 100%;
}

.main-button {
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #222222;
  cursor: pointer;
}

.main-button-text {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .add-to-cart-btn {
    width: 193px;
  }
}
