.form {
  padding: 30px 0 10px;
}

.action {
  display: flex;
  gap: 20px;
  flex-direction: column-reverse;
}

@media screen and (min-width: 768px) {
  .action {
    flex-direction: row;
    align-self: flex-end;
  }
}
