.showcase {
  min-width: 320px;
  max-width: 1280px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: fit-content(230px) auto min-content;
  min-height: 100vh;
  gap: 40px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1280px) {
  .showcase {
    padding: 0;
  }
}
