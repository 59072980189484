.select {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 9px 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  box-sizing: border-box;
  outline: 2px solid transparent;
}

.default-value {
  cursor: default;
}

.disabled {
  color: #c3c3c3;
  background: #f2f2f2;
}



.select::after {
  content: '';
  width: 12px;
  height: 8px;
  background-color: black;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  transition: background-color 100ms linear;
}

.disabled.select::after {
  transition: all 100ms linear;
  background-color: #c3c3c3;

}


.select:focus:not(.disabled) {
  transition: all 250ms linear;
  background-color: #fcf7eb;
  border-color: #ffc43f;
}

.options {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 12px 0;
  margin: 0;
  position: absolute;
  top: 45px;
  right: 0;
  left: 0;
  z-index: 1;
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.1);
}

.active {
  background-color: #ffc43f;
}

.option {
  cursor: default;
  padding: 10px 0 10px 20px;
}

.option:hover {
  transition: all 150ms linear;
  background-color: #ffc43f;
}



.error {
  transition: outline 0.3s ease-in;
  outline: 2px solid #ef2525;
}