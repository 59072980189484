.tooltip {
  display: flex;
  position: relative;
}

.tooltip-message {
  position: absolute;
  display: flex;
  background-color: #ecf7ed;
  border: 1px solid #f2f2f2;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 12px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  min-width: 450px;
}

.top {
  top: -45px;
}

.right {
  top: 50%;
  left: calc(100% + 10px);
  transform: translateX(0) translateY(-50%);
}

.bottom {
  bottom: -45px;
}

.left {
  left: auto;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
