.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 98px;
}

.label {
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #333333;
}

.input {
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 20px;
  color: #222222;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid lightgray;
  
  outline: 2px solid transparent;
}

.input::placeholder {
  font-size: 14px;
  color: lightgray;
}

.input:focus {
  transition: all 250ms linear;
  background-color: #fcf7eb;
  border: 1px solid #ffc43f;
}

.error {
  transition: outline 0.3s ease-in;
  outline: 2px solid #ef2525;
}

.highlighted {
  font-size: 14px;
  color: #ef2525;
}
