.icon {
  transition: all 200ms ease-out;
  
}

.icon:hover {
  transition: all 250ms ease-in;
  fill: #ef2525;
}


