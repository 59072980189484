.checkout-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    gap: 20px;
}

.title {
    font-family: 'Nunito';
    font-size: 38px;
    color: #5ec343;
    text-align: center;
}

.subtitle {
    font-size: 14px;
    text-align: center;
}

.text {
    text-align: center;
}

.link {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: #222222;
    padding: 5px 10px;
    background-color: #fc0;
    border-radius: 4px;
    transition: opacity 200ms linear;
  }
  
  .link:hover {
    opacity: 0.7;
    transition: opacity 200ms linear;
  }