.grid {
  display: grid;
  gap: 20px;
  justify-content: center;
}

.block {
  display: block;
}

@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: minmax(max-content, 210px) 1fr;
  }
}
