.filter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #e8ebf0;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  align-self: start;
  align-items: flex-start;
}

.title {
  font-family: 'Nunito';
  font-size: 18px;
  padding: 0 0 20px;
}
