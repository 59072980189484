.toast {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 8px 14px;
  border-radius: 4px;
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  animation: fade 200ms linear;
  z-index: 5;
  box-sizing: border-box;
  min-width: 280px;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.action {
  text-align: center;
  border-top: 1px solid #fff;
  padding-top: 5px;
  width: 100%;
  cursor: pointer;
  transition: opacity 150ms linear;
}

.action:hover {
  opacity: 0.7;
  transition: opacity 150ms linear;
}

.info {
  color: #222222;
  background-color: #fcf7eb;
  box-shadow: 0px 6px 12px rgba(130, 82, 208, 0.16);
}

.success {
  background-color: #5ec343;
  box-shadow: 0px 6px 12px rgba(0, 176, 49, 0.16);
}

.warning {
  background-color: #ffa801;
  box-shadow: 0px 6px 12px rgba(245, 152, 33, 0.16);
}

.error {
  background-color: #ef2525;
  box-shadow: 0px 6px 12px rgba(255, 7, 7, 0.16);
}

@media screen and (min-width: 768px) {
  .toast {
    flex-direction: row;
    width: auto;
    gap: 25px;
  }

  .action {
    text-align: left;
    padding-top: 0;
    border-top: none;
    padding-left: 10px;
    border-left: 1px solid #fff;
    width: auto;
  }
}
