.header {
    background: #fcf7eb;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
  .header {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
}
