.cart-summary {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fcf7eb;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  flex-shrink: 0;
  width: 100%;
}

.cart-summary-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.heading {
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 26px;
  padding: 0;
}

.profit-amount {
  font-weight: 700;
  color: #f44336;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .cart-summary {
    min-width: 380px;
    width: auto;
  }
}
