.button {
  font-family: 'Open Sans';
  font-size: 15px;
  color: #222222;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 7px 17px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}

.button:hover:not(:disabled) {
  transition: opacity 0.3s ease-out;
  opacity: 0.5;
}

.button:disabled::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms linear;
  background-color: #f2f2f2;
  opacity: 0.6;
  cursor: default;
}

.primary {
  color: #fff;
  background-color: #5ec343;
}

.secondary {
  background-color: transparent;
  color: #333333;
  border: 1px solid lightgray;
}
